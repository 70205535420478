<template>
  <div>
    <v-card class="pa-0 tab-item-card" flat>
      <v-img class="section-title-bg-img" src="../../assets/agreement-summary.jpg"></v-img>
      <div class="text-center py-12 px-5">
        <span class="text-h3 text-sm-h2 text-md-h1">Sign agreement</span>
      </div>
      <v-container class="px-5 py-5 py-sm-12">
        <p class="primary--text text--darken-3 text-h5 font-weight-light">Your are signing the tenancy agreement and will not be legally binding until the landlord or agent have counter sign, which you will be notified.</p>
        <v-card class="grey lighten-4 mt-12 pa-3" outlined flat>
          <v-card-title class="white">
            <span class="text-caption">I, Jane Doe, agree to the terms of this agreement and I agree that my typed name below can be used as a digital representation of my signature to that fact.</span>
          </v-card-title>
          <v-card-text class="white pt-3">
            <v-tabs color="secondary" v-model="signTab">
              <v-tab>
                <v-icon left small>mdi-keyboard</v-icon>
                Type
              </v-tab>
              <v-tab>
                <v-icon left small>mdi-pencil</v-icon>
                Sign
              </v-tab>
              <v-tab-item>
                <v-card class="my-3 pa-3" flat outlined>
                  <v-row>
                    <v-col class="d-flex justify-start" cols="12">
                      <span class="mt-1 text-body-1">Type your name below to approve this agreement.</span>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="typedSignature"
                        id="typed-signature"
                        outlined
                        class="text--h4"
                      ></v-text-field>
                    </v-col>
                  </v-row>          
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card min-height="155" flat class="pa-5">
                 <SignaturePad />
                </v-card>
              </v-tab-item>
            </v-tabs>
            <div>
            <div class="mt-6 d-flex justify-end">
              <v-btn @click="signAgreement" :disabled="!typedSignature.length" :loading="loading" color="primary" width="200">
                <span class="secondary--text custom-transform-class text-none">Sign</span>
              </v-btn>
            </div>
            </div>
          </v-card-text>
        </v-card>
      </v-container>
      <div class="secondary text-center py-12">
        <v-container class="px-5 py-8">
          <p class="white--text text-h5 text-sm-h4">Congratulations on completing your agreement!</p>
        </v-container>
      </div>
    </v-card>
    <MainPagination 
      previousPage="/review/conditions"
      nextPage="/review/summary"
    />
  </div>
</template>

<script>
import SignaturePad from '@/components/SignaturePad.vue';
export default {
  name: 'agreement-sign-here',
  components: {
    SignaturePad
  },
  data() {
    return {
      loading: false,
      signTab: '',
      typedSignature: ''
    }
  },
  methods: {
    signAgreement() {
      this.loading = true

      setTimeout(() => {
        this.loading = false
        this.$router.push('/signing')
      }, 2500)
    }
  },
}
</script>

<style>

</style>