<template>
  <!-- Used in the agreement component -->
  <div id="app">
    <div class="row">
      <div class="col-12 mt-2">
        <VueSignaturePad
          id="signature"
          width="100%"
          height="200px"
          ref="signaturePad"
          :options="options"
        />
      </div>
    </div>
    <v-row justify="start">
      <v-col cols="3">
        <v-btn @click="undo" color="black">
          <v-icon left color="white">mdi-undo</v-icon>
          <span class="white--text">Undo</span>
        </v-btn>
      </v-col>
      <!-- <v-col cols="3">
        <v-btn @click="save" color="black">
          <span class="white--text">
            Save
          </span>
        </v-btn>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
export default {
  name: "signature-pad",
  data: () => ({
    options: {
      penColor: "#000"
    }
  }),
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      console.log(isEmpty);
      console.log(data);
    },

  }
};
</script>

<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #000, #000);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
